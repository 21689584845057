import { StaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Navbar from "react-bootstrap/Navbar"
import Button from "react-bootstrap/Button"
import Nav from "react-bootstrap/Nav"
import styles from "./header.module.css"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Responsive from 'react-responsive';

const Desktop = props => <Responsive {...props} minWidth={1130} />;
const Tablet = props => <Responsive {...props} minWidth={979} maxWidth={1130} />;
const Mobile = props => <Responsive {...props} maxWidth={978} />;
// const Default = props => <Responsive {...props} minWidth={768} />;

const openNav = () => {
  if(typeof document !== "undefined"){
    document.getElementById("myNav").style.height = "100%";

  }
}

const closeNav = () => {
  if(typeof document !== "undefined"){

    document.getElementById("myNav").style.height = "0%";
  }
}

let scrolling = 'xxx';

class Header extends React.Component{

  constructor(props) {
    super(props);
    if(typeof document !== "undefined"){
      document.body.addEventListener('scroll', this.handleScroll);
    }
  }
  componentWillMount() {
    // This code adds the script to add the HubSpot chat to the page, was added to the header so it would appear in every page.
    if(typeof document !== "undefined"){
      const script = document.createElement('script');

      script.src = '//js.hs-scripts.com/5040948.js';
      script.async = true;
      script.defer = true;
      script.id = 'hs-script-loader';

      document.body.appendChild(script);
    }
  }
  componentWillUnmount() {
    if(typeof document !== "undefined"){
      document.body.addEventListener('scroll', this.handleScroll);
    }
  }

  handleScroll = () => {
    if(typeof document !== "undefined"){
      const lastScrollY =  document.body.scrollTop;

      if (lastScrollY >120){
        document.getElementById('navbar').classList.add(styles.scrolling);
      }
      else{
          document.getElementById('navbar').classList.remove(styles.scrolling);
      }
    }
  };


  render(){
    return (
      <StaticQuery
        query={graphql`query MyQuery {
         logobig:
            imageSharp(fixed: {originalName: {eq: "exeboardlogo.png"}}) {
            fixed {
              aspectRatio
              base64
              height
              originalName
              src
              srcSet
              srcSetWebp
              tracedSVG
              srcWebp
              width
            }
          }
         logosmall:
            imageSharp(fixed: {originalName: {eq: "exeboardlogosmall.png"}}) {
            fixed {
              aspectRatio
              base64
              height
              originalName
              src
              srcSet
              srcSetWebp
              tracedSVG
              srcWebp
              width
            }
          }
        }
`}
        render={data => {
          const pathname = this.props.location.pathname;
          return (
            <Navbar className={`${styles.navbarExeboard} ${scrolling}`} id="navbar" collapseOnSelect expand="lg">
              <Navbar.Brand href="#home">
                <div className={styles.logoContainer}>
                  <Mobile> <a href="/"><Img fixed={data.logosmall.fixed}/></a> </Mobile>
                  <Desktop> <a href="/"><Img fixed={data.logobig.fixed}/></a> </Desktop>
                  <Tablet> <a href="/"><Img fixed={data.logosmall.fixed}/></a> </Tablet>
                </div>
              </Navbar.Brand>
              <Mobile>
                <Nav.Link className={styles.navLinkLoginSmall}  href="https://app.exeboard.com"><Button className={styles.loginButtonSmall}>Log In</Button></Nav.Link>
                <Button aria-controls="fullscreen-nav" className={styles.toggleIcon} onClick={openNav}>
                  <i className={`${styles.faBars} fa fa-bars`}></i>
                </Button>
                <div id="myNav" className={styles.overlay}>
                  <a href="javascript:void(0)" className={styles.closebtn} onClick={closeNav}>&times;</a>
                  <div className={styles["overlayContent"]}>
                    <a  style={{fontSize: 18}} id="overlay" className={`${styles["navlink"]} ${styles.navMobile } ${ pathname==='/' ? styles.selected: ''}`} href="/">Home</a>
                    <a style={{fontSize: 18}} id="overlay" className={`${styles["navlink"]}  ${styles.navMobile } ${ pathname==='/product/' ? styles.selected: ''}`} href="/product">Product</a>
                    <a style={{fontSize: 18}} id="overlay" className={`${styles["navlink"]}  ${styles.navMobile } ${ pathname==='/security/' ? styles.selected: ''}`} href="/security">Security</a>
                    <a style={{fontSize: 18}}id="overlay" className={`${styles["navlink"]}  ${styles.navMobile } ${ pathname==='/why/' ? styles.selected: ''}`} href="/why">Why Exeboard?</a>
                    {/*<a style={{fontSize: 18}} id="overlay" className ={`${styles["navlink"]}  ${styles.navMobile } ${ pathname==='/help' ? styles.selected: ''}`} href="/help">Help</a>*/}
                    <a  style={{fontSize: 18}} className={styles.loginButtonOverlay} href="https://app.exeboard.com">Login</a>
                  </div>

                </div>
              </Mobile>
              <Desktop>
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className={`ml-auto ${styles.linksContainer}`}>
                    <Nav.Link className={`${styles["navlink"]} ${ pathname==='/' ? styles.selected: ''}`}  href="/">Home</Nav.Link>
                    <Nav.Link className={`${styles["navlink"]} ${ pathname==='/product/' ? styles.selected: ''}`}  href="/product/">Product</Nav.Link>
                    <Nav.Link className={`${styles["navlink"]} ${ pathname==='/security/' ? styles.selected: ''}`}  href="/security/">Security</Nav.Link>
                    <Nav.Link className={`${styles["navlink"]} ${ pathname==='/why/' ? styles.selected: ''}`}  href="/why/">Why Exeboard?</Nav.Link>
                    {/*<Nav.Link className={`${styles["navlink"]} ${ pathname==='/help/' ? styles.selected: ''}`}  href="/help/">Help</Nav.Link>*/}
                    <Nav.Link   href="https://app.exeboard.com"><Button className={styles.loginButton}>Log In</Button></Nav.Link>
                    <Nav.Link><Button
                      onClick={()=>{
                        if(typeof document !== "undefined"){
                          document.getElementById('contactD').scrollIntoView({ behavior: 'smooth', block: 'center' });
                        }

                      }}
                      className={styles.getStartedButton}>Get Started</Button></Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Desktop>
              <Tablet>
                <Navbar id="responsive-navbar-nav">
                  <Nav className={`ml-auto ${styles.linksContainer}`}>
                    <Nav.Link className={`${styles["navlink"]} ${ pathname==='/' ? styles.selected: ''}`}  href="/">Home</Nav.Link>
                    <Nav.Link className={`${styles["navlink"]} ${ pathname==='/product/' ? styles.selected: ''}`}  href="/product/">Product</Nav.Link>
                    <Nav.Link className={`${styles["navlink"]} ${ pathname==='/security/' ? styles.selected: ''}`}  href="/security/">Security</Nav.Link>
                    <Nav.Link className={`${styles["navlink"]} ${ pathname==='/why/' ? styles.selected: ''}`}  href="/why/">Why Exeboard?</Nav.Link>
                    {/*<Nav.Link className={`${styles["navlink"]} ${ pathname==='/help/' ? 'selected': ''}`}  href="/help">Help</Nav.Link>*/}
                    <Nav.Link href="https://app.exeboard.com"><Button className={styles.loginButton}>Log In</Button></Nav.Link>
                    <Nav.Link><Button
                      onClick={()=>{
                        if(typeof document !== "undefined"){
                          document.getElementById('contactT').scrollIntoView({ behavior: 'smooth', block: 'start' });
                        }
                      }}
                      className={styles.getStartedButton}>Get Started</Button></Nav.Link>
                  </Nav>
                </Navbar>
              </Tablet>
            </Navbar>
          )
        }
        }
      />
    )
  }

}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}
export default Header
