import { StaticQuery } from "gatsby";
import React from "react";
import styles from './footerBlock.module.css';
import { graphql, Link } from "gatsby";
import { FaLinkedinIn } from "react-icons/fa";
import Img from "gatsby-image";

import { Fragment } from "react"


const FooterBlock = (props) => {
  return (
    <StaticQuery
      query={graphql`query Footer {
       logobig:
          imageSharp(fixed: {originalName: {eq: "Group.png"}}) {
          fixed(height: 65, width: 160) {
            aspectRatio
            base64
            height
            originalName
            src
            srcSet
            srcSetWebp
            tracedSVG
            srcWebp
            width
          }
       }
      }`
      }
      render={
        data => {
          return (
            <Fragment>
                <div className={styles.footerMobile}>
                  <ul className={styles.footerText}>
                    <li>Ejercito Nacional 843-B, Ciudad de México, México 11520</li>
                    <li>104 Crandon Blvd., Suite 323, Key Biscayne, Florida 33149</li>
                  </ul>

                  <p className={styles.footerText}>
                    info@exeboard.com
                  </p>

                  <p className={styles.footerTextLast}>
                    <Link to="/policy">Terms and conditions</Link>
                  </p>

                  <a className={styles.linkedinButton} href="https://linkedin.com">
                    <FaLinkedinIn  className={styles.linkedinIcon} size={18}/>
                    <span>Linkedin</span>
                  </a>

                  <div className={styles.logoContainer}>
                    <Img fixed={data.logobig.fixed} alt=""/>
                  </div>

                  <p className={styles.allRights}>
                    2019. All rights reserved.
                  </p>
                </div>

                <div className={styles.footerDesktop}>
                  <div className={styles.footerLogos}>
                    <div className={styles.logoContainerDesktop}>
                      <Img fixed={data.logobig.fixed} alt=""/>
                    </div>
                    <div className={styles.footerTextContainer}>
                      <ul className={styles.footerText}>
                        <li>Ejercito Nacional 843-B, Ciudad de México, México 11520</li>
                        <li>104 Crandon Blvd., Suite 323, Key Biscayne, Florida 33149</li>
                      </ul>
                      <p className={styles.footerText}>
                        info@exeboard.com
                      </p>
                    </div>
                    <div className={styles.linkedinContainer}>
                      <p className={styles.footerText}>
                        <Link to="/policy">Terms and conditions</Link>
                      </p>
                      <a className={styles.linkedinButtonDesktop} href="https://linkedin.com/company/exeboard">
                        <FaLinkedinIn  className={styles.linkedinIcon} size={18}/>
                        Linkedin
                      </a>
                    </div>
                  </div>

                  <div className={styles.rightsReserved}>
                    <p className={styles.allRights}>
                      2019. All rights reserved.
                    </p>
                  </div>
                </div>

                <div className={styles.footerTablet}>
                  <div className={styles.footerLogos}>
                    <div className={styles.logoContainerDesktop}>
                      <Img fixed={data.logobig.fixed} alt=""/>
                    </div>
                    <div className={styles.footerTextContainer}>
                      <ul className={styles.footerText}>
                        <li>Ejercito Nacional 843-B, Ciudad de México, México 11520</li>
                        <li>104 Crandon Blvd., Suite 323, Key Biscayne, Florida 33149</li>
                      </ul>
                      <p className={styles.footerText}>
                        info@exeboard.com
                      </p>
                    </div>
                    <div className={styles.linkedinContainer}>
                      <p className={styles.footerText}>
                        <Link to="/policy">Terms and conditions</Link>
                      </p>
                      <a className={styles.linkedinButtonDesktop} href="https://linkedin.com/company/exeboard">
                        <FaLinkedinIn  className={styles.linkedinIcon} size={18}/>
                        Linkedin
                      </a>
                    </div>
                  </div>

                  <div className={styles.rightsReserved}>
                    <p className={styles.allRights}>
                      2019. All rights reserved.
                    </p>
                  </div>
                </div>
            </Fragment>
          )
        }
      }
    >

    </StaticQuery>
  )
}

FooterBlock.propTypes = {}

FooterBlock.defaultProps = {}
export default FooterBlock
