import { StaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Navbar from "react-bootstrap/Navbar"
import Button from "react-bootstrap/Button"
import Nav from "react-bootstrap/Nav"
import styles from "./headerDark.module.css"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Responsive from 'react-responsive';

const Desktop = props => <Responsive {...props} minWidth={1130} />;
const Tablet = props => <Responsive {...props} minWidth={979} maxWidth={1130} />;
const Mobile = props => <Responsive {...props} maxWidth={978} />;

const openNav = () => {
  if(typeof document !== "undefined") {
    document.getElementById("myNav").style.height = "100%";

  }
  }

const closeNav = () => {
  if(typeof document !== "undefined"){
    document.getElementById("myNav").style.height = "0%";
  }

}
const HeaderDark = (props) => {

  return (
    <StaticQuery
      query={graphql`query HeaderDark {
       logobig:
          imageSharp(fixed: {originalName: {eq: "exeboardLogoClear.png"}}) {
          fixed {
            aspectRatio
            base64
            height
            originalName
            src
            srcSet
            srcSetWebp
            tracedSVG
            srcWebp
            width
          }
        }
       logosmall:
          imageSharp(fixed: {originalName: {eq: "exeboardLogoSmallClear.png"}}) {
          fixed {
            aspectRatio
            base64
            height
            originalName
            src
            srcSet
            srcSetWebp
            tracedSVG
            srcWebp
            width
          }
        }
      }
`}
      render={data => {
        const pathname = props.location.pathname;
        return (
          <Navbar className={styles.navbarExeboard} collapseOnSelect expand="lg">
            <Navbar.Brand href="#home">
              <div className={styles.logoContainer}>
                <Mobile> <a href="/"><Img fixed={data.logosmall.fixed}/></a> </Mobile>
                <Desktop> <a href="/"><Img fixed={data.logobig.fixed}/></a> </Desktop>
                <Tablet> <a href="/"><Img fixed={data.logosmall.fixed}/></a> </Tablet>
              </div>
            </Navbar.Brand>

            <Mobile>
              <Nav.Link className={styles.navLinkLoginSmall} href="https://app.exeboard.com"><Button className={styles.loginButtonSmall}>Log In</Button></Nav.Link>
              <Button aria-controls="fullscreen-nav" className={styles.toggleIcon} onClick={openNav}>
                <i className={`${styles.faBars} fa fa-bars`}></i>
              </Button>
              <div id="myNav" className={styles.overlay}>
                <a href="javascript:void(0)" className={styles.closebtn} onClick={closeNav}>&times;</a>
                <div className={styles["overlayContent"]}>
                  <a id="overlay" className={`${styles["navlink"]} ${ pathname==='/' ? styles.selected: ''}`} href="/">Home</a>
                  <a id="overlay" className={`${styles["navlink"]} ${ pathname==='/product' ? styles.selected: ''}`} href="/product">Product</a>
                  <a id="overlay" className={`${styles["navlink"]} ${ pathname==='/security' ? styles.selected: ''}`} href="/security">Security</a>
                  <a id="overlay" className={`${styles["navlink"]} ${ pathname==='/why' ? styles.selected: ''}`} href="/why">Why Exeboard?</a>
                  <a id="overlay" className={`${styles["navlink"]} ${ pathname==='/help' ? styles.selected: ''}`} href="/help">Help</a>
                  <a  className={styles.loginButtonOverlay} href="https://app.exeboard.com">Login</a>
                </div>

              </div>
            </Mobile>
            <Desktop>
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className={`ml-auto ${styles.linksContainer}`}>
                  <Nav.Link className={`${styles["navlink"]} ${ pathname==='/' ? styles.selected: ''}`}  href="/">Home</Nav.Link>
                  <Nav.Link className={`${styles["navlink"]} ${ pathname==='/product' ? styles.selected: ''}`}  href="/product">Product</Nav.Link>
                  <Nav.Link className={`${styles["navlink"]} ${ pathname==='/security' ? styles.selected: ''}`}  href="/security">Security</Nav.Link>
                  <Nav.Link className={`${styles["navlink"]} ${ pathname==='/why' ? styles.selected: ''}`}  href="/why">Why Exeboard?</Nav.Link>
                  <Nav.Link className={`${styles["navlink"]} ${ pathname==='/help' ? styles.selected: ''}`}  href="/help">Help</Nav.Link>
                  <Nav.Link  href="https://app.exeboard.com"><a  href="https://app.exeboard.com" className={styles.loginButton}>Log In</a></Nav.Link>
                  <Nav.Link><Button
                    onClick={()=>{
                      if(typeof document !== "undefined"){
                        document.getElementById('contactD').scrollIntoView({ behavior: 'smooth', block: 'center' });
                      }

                    }}
                    className={styles.getStartedButton}>Get Started</Button></Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Desktop>
            <Tablet>
              <Navbar id="responsive-navbar-nav">
                <Nav className={`ml-auto ${styles.linksContainer}`}>
                  <Nav.Link className={`${styles["navlink"]} ${ pathname==='/' ? 'selected': ''}`}  href="/">Home</Nav.Link>
                  <Nav.Link className={`${styles["navlink"]} ${ pathname==='/product/' ? 'selected': ''}`}  href="/product">Product</Nav.Link>
                  <Nav.Link className={`${styles["navlink"]} ${ pathname==='/security/' ? 'selected': ''}`}  href="/security">Security</Nav.Link>
                  <Nav.Link className={`${styles["navlink"]} ${ pathname==='/why/' ? 'selected': ''}`}  href="/why">Why Exeboard?</Nav.Link>
                  <Nav.Link className={`${styles["navlink"]} ${ pathname==='/help/' ? 'selected': ''}`}  href="/help">Help</Nav.Link>
                  <Nav.Link  href="https://app.exeboard.com"><Button className={styles.loginButton}>Log In</Button></Nav.Link>
                  <Nav.Link><Button
                    onClick={()=>{
                      if(typeof document !== "undefined"){
                        document.getElementById('contactT').scrollIntoView({ behavior: 'smooth', block: 'start' });
                      }
                    }}
                    className={styles.getStartedButton}>Get Started</Button></Nav.Link>
                </Nav>
              </Navbar>
            </Tablet>
          </Navbar>
        )
      }
      }
    />
  )
}

HeaderDark.propTypes = {
  siteTitle: PropTypes.string,
}

HeaderDark.defaultProps = {
  siteTitle: ``,
}
export default HeaderDark
