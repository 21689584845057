/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { Location } from '@reach/router';
import Header from "./Header/header"
import HeaderDark from "./HeaderDark/HeaderDark"
import FooterBlock from "../components/FooterBlock/FooterBlock";
import "./layout.css"

const Layout = ({ children, ...props }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <LayoutRender data={data} children={children} {...props}/>
    )}
  />
)

const LayoutRender =  ({ children, data, ...props }) => {
  const pathname = props.location.pathname;
  let isWhite = pathname !== '/why/';

  return (
    <div className='bodyExeboard'>
      <link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
        integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
        crossOrigin="anonymous"
      />
      <link href="https://fonts.googleapis.com/css?family=Montserrat:300,500,700&display=swap" rel="stylesheet"/>


      <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.9.0/css/all.css"
            integrity="sha384-vlOMx0hKjUCl4WzuhIhSNZSm2yQCaf0mOU1hEDK/iztH3gU4v5NMmJln9273A6Jz" crossOrigin="anonymous" />
      <script src="https://unpkg.com/react/umd/react.production.js" crossOrigin/>

      <script
        src="https://unpkg.com/react-dom/umd/react-dom.production.js"
        crossOrigin
      />
      <script
        src="https://unpkg.com/react-bootstrap@next/dist/react-bootstrap.min.js"
        crossOrigin
      />


      <script>var Alert = ReactBootstrap.Alert;</script>
      {
        isWhite ?
          <Header siteTitle={data.site.siteMetadata.title} {...props}/> :
          <HeaderDark siteTitle={data.site.siteMetadata.title} {...props}/>
      }
      <div
        style={{
          margin: `0 auto`,
          padding: `0`,
          paddingTop: 0,
        }}
      >
        <main>{children}</main>
        <footer>
          <FooterBlock/>
        </footer>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default props => (
  <Location>
    {locationProps => <Layout {...locationProps} {...props} />}
  </Location>
);
